import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//ApplePay
import ApplePay from '../pages/Pay/ApplePay'
import ApplePayButton from '../pages/Pay/ApplePayButton'

const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },

  // //profile
  // { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/applepay" /> },
]

const publicRoutes = [
  // { path: "/logout", component: Logout },
  // { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  { path: "/applepay/:id", component: ApplePay },
  { path: "/applepay/v2/:id", component: ApplePayButton },
]

export { authProtectedRoutes, publicRoutes }
