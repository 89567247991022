import {
    GET_PAYMENTREQUEST_DETAIL_PUBLIC,
    GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
    GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
  } from "./actionTypes"
  

  export const getPaymentRequestDetailPublic = id => ({
    type: GET_PAYMENTREQUEST_DETAIL_PUBLIC,
    id,
  })
  
  export const getPaymentRequestDetailPublicSuccess = paymentrequestDetails => ({
    type: GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
    payload: paymentrequestDetails,
  })
  
  export const getPaymentRequestDetailPublicFail = error => ({
    type: GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
    payload: error,
  })
  
  