import {
  SET_APPLEPAY_INITIATE_PAYMENT,
  SET_APPLEPAY_INITIATE_PAYMENT_SUCCESS,
  SET_APPLEPAY_INITIATE_PAYMENT_FAIL,
  } from "./actionTypes"
  

  export const setApplePayInitiatePayment = data => ({
    type: SET_APPLEPAY_INITIATE_PAYMENT,
    payload: data,
  })
  
  export const setApplePayInitiatePaymentSuccess = data => ({
    type: SET_APPLEPAY_INITIATE_PAYMENT_SUCCESS,
    payload: data,
  })
  
  export const setApplePayInitiatePaymentFail = error => ({
    type: SET_APPLEPAY_INITIATE_PAYMENT_FAIL,
    payload: error,
  })
  
  