import {

  SET_APPLEPAY_INITIATE_PAYMENT,
  SET_APPLEPAY_INITIATE_PAYMENT_SUCCESS,
  SET_APPLEPAY_INITIATE_PAYMENT_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    initiatePayment: {},
    error: {},
  }
  
  const Payment = (state = INIT_STATE, action) => {
    switch (action.type) {
      
      case SET_APPLEPAY_INITIATE_PAYMENT:
        return {
          ...state,
          initiatePayment: action.payload,
          loading: true,
        }
  
      case SET_APPLEPAY_INITIATE_PAYMENT_SUCCESS:
        return {
          ...state,
          initiatePayment: action.payload,
          loading: false,
        }
  
      case SET_APPLEPAY_INITIATE_PAYMENT_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
  
      default:
        return state
    }
  }
  
  export default Payment
  