import {

    GET_PAYMENTREQUEST_DETAIL_PUBLIC,
    GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
    GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    paymentRequestDetail: {},
    error: {},
  }
  
  const PaymentRequests = (state = INIT_STATE, action) => {
    switch (action.type) {
      
      case GET_PAYMENTREQUEST_DETAIL_PUBLIC:
        return {
          ...state,
          paymentRequestDetail: action.payload,
          loading: true,
        }
  
      case GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS:
        return {
          ...state,
          paymentRequestDetail: action.payload,
          loading: false,
        }
  
      case GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
  
      default:
        return state
    }
  }
  
  export default PaymentRequests
  