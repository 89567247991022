import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://pal.developerqa.info/api/"

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

// export async function get(url, config = {}) {
//   return await axiosApi.get(url, { ...config }).then(response => response.data);
// }

// export async function post(url, data, config = {}) {
//   return axiosApi
//     .post(url, { ...data }, { ...config })
//     .then(response => response.data);
// }

// export async function put(url, data, config = {}) {
//   return axiosApi
//     .put(url, { ...data }, { ...config })
//     .then(response => response.data);
// }

// export async function del(url, config = {}) {
//   return await axiosApi
//     .delete(url, { ...config })
//     .then(response => response.data);
// }


export async function get(url) {
  const uri = API_URL + url

  return await axiosApi
    .get(uri)
    .then(response => response.data)
    .catch(error => {
      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }
    })
}

export async function post(url, data) {
  const uri = API_URL + url
 
  return await axiosApi
    .post(uri, data)
    .then(response => response.data)
    .catch(error => {
 

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

     
    })
}


export async function put(url, data, config = {}) {
  await getAuthorized()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {


      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

    })
}

export async function del(url, config = {}) {
  await getAuthorized()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => {


      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }


    })
}

export async function patch(url, data, config = {}) {
  await getAuthorized()
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
 

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

    })
}



