import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
 
  GET_PAYMENTREQUEST_DETAIL_PUBLIC,
} from "./actionTypes"
import {

  getPaymentRequestDetailPublicSuccess,
  getPaymentRequestDetailPublicFail,
} from "./actions"

//Include Both Helper File with needed methods

import {

  getPaymentRequestsDetailsPublic,
  
} from "helpers/fakebackend_helper"


function* _getPaymentRequestDetailPublic({ id }) {
  try {

    const response = yield call(getPaymentRequestsDetailsPublic, id)

    if (response) {
      if (response?.isSuccess)
        yield put(getPaymentRequestDetailPublicSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestDetailPublicFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentRequestDetailPublicFail(response))
        }
      }
    } else
      yield put(
        getPaymentRequestDetailPublicFail({
          message: id + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getPaymentRequestDetailPublicFail(error?.message))
  }
}







function* PaymentrequestsSaga() {

  yield takeEvery(
    GET_PAYMENTREQUEST_DETAIL_PUBLIC, _getPaymentRequestDetailPublic
  )
}

export default PaymentrequestsSaga
