import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
 
  SET_APPLEPAY_INITIATE_PAYMENT,
} from "./actionTypes"
import {

  setApplePayInitiatePaymentSuccess,
  setApplePayInitiatePaymentFail,
} from "./actions"

//Include Both Helper File with needed methods

import {

  setApplePayInitiatePayment,
  
} from "helpers/fakebackend_helper"


function* _setApplePayInitiatePayment({ payload: data }) {
  try {
    const response = yield call(setApplePayInitiatePayment, data)

    if (response) {
      if (response?.isSuccess)
        yield put(setApplePayInitiatePaymentSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setApplePayInitiatePaymentFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setApplePayInitiatePaymentFail(response))
        }
      }
    } else
      yield put(
        setApplePayInitiatePaymentFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(setApplePayInitiatePaymentFail(error?.message))
  }
}

function* PaymentSaga() {

  yield takeEvery(
    SET_APPLEPAY_INITIATE_PAYMENT, _setApplePayInitiatePayment
  )
}

export default PaymentSaga
